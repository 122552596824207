<template>
    <div class="px-2 switcher-wrapper">
      <sdButton
        class="switch-btn table-view"
        :class="activeView === PORTFOLIO_VIEWS.TABLE_VIEW && 'icon-white'"
        type="primary"
        :outlined="activeView !== PORTFOLIO_VIEWS.TABLE_VIEW"
        @click="click(PORTFOLIO_VIEWS.TABLE_VIEW)"
      >
        {{ getButtonText(props.isPortfolio) }}
      </sdButton>
      <sdButton
        class="switch-btn graph-view"
        :class="activeView === PORTFOLIO_VIEWS.GRAPH_VIEW && 'icon-white'"
        type="primary"
        :outlined="activeView !== PORTFOLIO_VIEWS.GRAPH_VIEW"
        @click="click(PORTFOLIO_VIEWS.GRAPH_VIEW)"
      >
        {{ $t("generic.buttons.graph_view") }}
      </sdButton>
      <sdButton
        class="switch-btn fund-view"
        :class="activeView === PORTFOLIO_VIEWS.FUNDS_VIEW && 'icon-white'"
        type="primary"
        :outlined="activeView !== PORTFOLIO_VIEWS.FUNDS_VIEW"
        @click="click(PORTFOLIO_VIEWS.FUNDS_VIEW)"
      >
        {{ $t("generic.buttons.funds_view") }}
      </sdButton>
    </div>
</template>
<script setup>
import { ref, defineEmits, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { PORTFOLIO_VIEWS } from "../../config/portfolio/constants";

const { t } = useI18n();

const props = defineProps({
    isPortfolio: {
        type: Number,
        default: 0
    },
});

const emit = defineEmits(['changeViewmode']);
const activeView = ref(PORTFOLIO_VIEWS.TABLE_VIEW);

const click = async (view) => {
    activeView.value = view;
    emit('changeViewmode', view);
};

const getButtonText = (value) => {
    switch(value) {
        case 0:
        return t("generic.buttons.asset_basket");
        case 1:
        return t("generic.buttons.asset_portfolio");
        case 2:
        return t("generic.buttons.portfolios");
        default:
        return t("generic.buttons.asset_basket"); 
    }
};
</script>
<style scoped lang="scss">
@import "~@/static/css/colors.scss";

.switcher-wrapper {
margin-bottom: 24px;
display: flex;
.switch-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 80%;
    padding: 16px 0 !important;
    &:focus {
    background-color: $text-light-color;
    }
    &:hover {
    background-color: $text-light-color;
    color: white;
    border: none;
    }
    &.table-view {
    border-top-left-radius: 30px  !important;
    border-bottom-left-radius: 30px  !important;
    }
    &.fund-view {
    border-top-right-radius: 30px  !important;
    border-bottom-right-radius: 30px  !important;
    }
    &.graph-view {
    border-left: none;
    border-right: none;
    }
  }
} 

@media only screen and (max-width: 768px) {
    .switch-btn {
        font-size: 11px;
    }
}
</style>