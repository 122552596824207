<template>
  <div class="portfolio-table">
    <portfolio-switcher @change-viewmode="changeView" :isPortfolio="1" />
    <a-row>
      <a-col :sm="24" :xs="24" v-if="activeView === PORTFOLIO_VIEWS.TABLE_VIEW">
        <div class="portfolio-basket-funds">
          <portfolio-table @selection-changed="handleChangeSelect"></portfolio-table>
        </div>
      </a-col>
      <a-col :xs="24" v-if="activeView === PORTFOLIO_VIEWS.GRAPH_VIEW">
        <portfolio-graph></portfolio-graph>
      </a-col>
      <a-col :xs="24" v-if="activeView === PORTFOLIO_VIEWS.FUNDS_VIEW">
        <basket-graph :graph="printGraph"></basket-graph>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import PortfolioTable from "./PortfolioTable.vue";
import PortfolioGraph from "../PortfolioGraph.vue";
import BasketGraph from "../BasketGraph.vue";
import PortfolioSwitcher from "../PortfolioSwitcher.vue";
import { PORTFOLIO_VIEWS } from "../../../config/portfolio/constants";
import { useBasketStore } from "@/stores/BasketStore";

const basketStore = useBasketStore();

const activeView = ref(PORTFOLIO_VIEWS.TABLE_VIEW);
const selectedData = ref([]);
const printGraph = ref([]);

const changeView = (e) => {
  activeView.value = e;
};

const handleChangeSelect = (e) => {
  selectedData.value = e;
};

watch(() => selectedData.value, async () => {
  if (selectedData.value.length > 0) {
    printGraph.value = await basketStore.generateGraph(selectedData.value.map((e) => e.isin_tkr_id));
  }
});

onMounted(async () => {
  if (selectedData.value.length) {
    printGraph.value = await basketStore.generateGraph(selectedData.value.map((e) => e.isin_tkr_id));
  }
});
</script>
<style scoped lang="scss">
.portfolio-basket-funds {
  .switcher {
    margin-bottom: 20px;
  }
}
.portfolio-table {
  background: #f2f2f2;
  padding: 72px 96px 1rem;
}
@media only screen and (max-width: 768px) {
  .portfolio-table {
    padding: 8px !important;
  }
}

</style>
