<template>
  <template v-if="!props.datasets.data">
  </template>
  <div v-else-if="props.datasets.data.length === 1" class="asset-info">
    <p class="asset-title">{{ t(`portfolio_overview.headings.assets`, 'Assets') }}</p>
    <div class="asset-info-items">
      <a-tooltip
        v-for="item in props.datasets.columns"
        :key="item"
        :title="t(`positions_fields.tooltips.${item}`, item)"
      >
      <div class="asset-item">
          <p>
            {{ t(`positions_fields.${item}`, item) }}
          </p>
          <p>
            {{ n(props.datasets.data[0][item], props.datasets.formats[item]?.format, {
              minimumFractionDigits: props.datasets.formats[item]?.decimalQty,
            }) }}
          </p>
        </div>
      </a-tooltip>
    </div>
  </div>
  <a-col :xs="24" :md="16" v-else class="asset-info">
    <table-printer
      :datasets="[tableData]"
      :sortable="false"
      :key-title="$t('portfolio_overview.headings.Assets')"
    ></table-printer>
  </a-col>
</template>
<script setup>
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import tablePrinter from "@/components/wiseral/tablePrinter.vue";

const { t, n } = useI18n();

const props = defineProps({
  datasets: {
    type: Object,
    default: function () {
      return {};
    }
  }
});
const tableData = computed(() => {
  const newData = {
    columns: props.datasets.data.map(item => item.key),
    data: props.datasets.columns.map(column => ({ key: t(`positions_fields.${column}`, column) })),
    formats: props.datasets.formats,
    title: 'Assets'
  };

  newData.columns.forEach(column => {
    newData.formats[column] = {
      format: 'percent',
      decimalQty: 1
    };
  });

  props.datasets.data.forEach(row => {
    props.datasets.columns.forEach((column, index) => {
      newData.data[index][row.key] = row[column];
    });
  });
  return newData;
});
</script>
<style scoped lang="scss">
.asset-title {
  font-size: 16px;
  font-weight: bold;
  color: #06A07D;
  border-bottom: 1px solid black;
  margin: 16px 16px 0 16px;
}
.asset-info-items {
  padding: 0 1rem;
}
@media only screen and (max-width: 768px) {
  .asset-title {
    margin: 16px 0 0 0;
    font-size: 16px;
    font-weight: 400;
    border: none;
    background-color: #004851;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    color: white;
  }
  .asset-info-items {
    padding: 1.5rem 2rem;
  }
}
.asset-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding-top: 12px;
  padding-bottom: 4px;
  color: black;
  font-size: 13px;
  p {
    margin: 0;
    &:last-child {
      font-weight: bold;
    }
  }
  &:last-child {
    font-size: 16px;
    color: #06A07D;
    p {
      font-weight: bold;
    }
  }
}
.asset-info {
  :deep(table) {
    tbody.ant-table-tbody tr:last-child td {
      font-size: 20px;
      font-weight: bold;
      color: #06A07D;
      border-color: black;
    }  
  }
}
</style>